/* Css for rresponsive */

@media screen and (min-width: 992px){
  .navbar-expand-lg .navbar-nav{
    align-items: center;
  }
}
@media screen and (min-width: 1450px) {
  .navbar .nav-bar .navbar-nav{
    margin: auto;
  }
}
@media screen and (max-width: 1400px){  
  .navbar .container{
    max-width: 100% !important;
  }
}
@media screen and (max-width: 1300px){
  #basic-navbar-nav .nav-link{
    font-size: 16px;
    margin: 10px 5px;
  }
}
@media screen and (max-width: 1280px){
  #basic-navbar-nav .m-auto.navbar-nav{
    margin: unset !important;
  }
  .seagram-drinks{
    padding: 40px 0;
  }
  .mt-120{
    margin-top: 50px !important;
  }
  .whiskeys-page .container{
    max-width: 100%;
    padding: 0 20px;
  }
  .whiskeys-page .title h1.lead{
    font-size: 50px;
  }
  .whiskeys-page .description, .whiskeys-page .features h1.lead, .whiskeys-page p, .whiskeys-page .features h4{
    font-size: 16px;
  }
  .whiskeys-page .features .card .card-title{
    font-size: 20px;
  }
}
@media screen and (max-width: 1200.5px){
  /* For Header */
  #basic-navbar-nav .navbar-nav .nav-link {
    margin: 5px;
    padding: 10px 9px;
    font-size: 14px;
  }
  .seagram-drinks img.img-fluid{
    margin-left: 10px;
    width: auto;
  }
}
@media screen and (max-width: 991.5px){
  .forMobileMenu{
    display: block !important;
    font-size: 11px !important;
    margin: 0 35px !important;
  }
  body.overflowHidden{
    overflow: hidden;
  }
  .container{
    max-width: 100% !important;
    padding: 10px 13px;
  }
  nav.navbar{
    height: inherit;
  }
  #basic-navbar-nav {
    position: absolute;
    background: #ffffff;
    width: 100%;
    top: 85px;
    z-index: 9;
    left: 0;
    height: 100vh;
    overflow: scroll;
  }
  #basic-navbar-nav .btn-dive-bar-header{
    position: relative;
    color: #c93c2b;
    background: transparent !important;
    font-size: 12px;
    right: 0;
    top: 0;
    width: fit-content;
    font-weight: bold;
  }
  #basic-navbar-nav .navbar-nav .nav-link{
    font-size: 12px;
    margin: 5px 20px;
    width: fit-content;
    font-weight: 600;
  }
  .header-white #basic-navbar-nav{
    background-color: #000000;
  }
  .header-white .navbar-toggler .icon-bar{
    background-color: #ffffff;
  }
  .mobileLogo{
    display: block;
    position: relative;
    right: 30px;
  }
  #basic-navbar-nav .navbar-nav .img-fluid, .descLogo{
    display: none;
  }
  .blended-whiskey, .honey-whiskey{
    margin-bottom: 25px;
  }
  .seagram-drinks{
    padding: 40px 20px;
  }
  .seagram-drinks .wiskey-desc{
    max-width: 422px;
  }
  .seagram-drinks .wiskey-desc p{
    font-size: 11px;
    line-height: 15.7px;
  }
  .whiskey-data{
    margin-bottom: 100px;
  }
  .features h1.lead, .features h4{
    font-size: 16px;
  }
  .whiskey-drinks-page .text-center .img-fluid{
    max-width: 100% !important;
  }
  .seagram-drinks .wiskey-desc a{
    bottom: 90px;
  }
  .whiskey-data .blend-box img, .whiskey-data .honey-box img, .whiskey-data .apple-box img{
    max-width: 90px;
  }
  .whiskey-data .blend-box, .whiskey-data .honey-box, .whiskey-data .apple-box{
    height: 303px;
  }
  .whiskey-data .blend-box h2, .whiskey-data .honey-box h2, .whiskey-data .apple-box h2 {
    margin: 20px auto 30px;
  }
  .whiskeys-page .title h1.lead{
    font-size: 35px;
  }
  .whiskeys-page .description, .whiskeys-page .features h1.lead, .whiskeys-page p, .whiskeys-page .features h4, .whiskeys-page .features .card .card-title{
    font-size: 14px;
  }
  .whiskeys-page a.btn-lg{
    font-size: 14px;
    height: inherit;
    line-height: 30px;
  }
  .whiskeys-page a img[alt= "arrow"] {
    padding-bottom: 3px;
  }
  .container-fluid .copy-container{
    margin: 70px auto auto;
  }
  .dive-bar-content.mission, .give-back-partner{
    padding: 70px 13.5px;
  }
  .dive-bar-content.mission h2{
    margin: 20px 0 30px;
  }
  .seagram-drinks img.img-fluid{
    margin-left: 20px;
  }
}
@media screen and (max-width: 767.5px){
  .stayTouch h1{
    font-size: 35px !important;
  }
  .banner{
    padding: 20px 0;
    height: 390px;
  }
  h1.lead, .newsLetter .stayTouch h1, .shop-online h1.lead{
    font-size: 35px;
  }
  .banner p, .find-it p{
    font-size: 12px;
    line-height: 1.33;
  }
  a.btn-lg, button.btn-lg, .seagram-drinks .explore-btn{
    padding: 6px 8px 3px;
    font-size: 11px;
    height: initial;
    line-height: 1.2;
  }
  .seagram-drinks .explore-btn{
    padding: 20px;
    font-size: 14px !important;
  }
  .btn-lg img[alt= "arrow"], a img[alt= "arrow"]{
    padding-bottom: 2px;
  }
  .find-it a img[alt= "arrow"]{
    padding-bottom: 4px;
  }
  .find-it h2, .seagram-drinks .lead{
    font-size: 55px;
  }
  .find-it{
    padding: 40px 0;
    height: 100%;
  }
  .list-inline a, footer ul li.list-inline-item, footer ul a{
    padding: 3px;
    margin-right: 0 !important;
    font-size: 11px;
  }
  /* .blend-box, .honey-box, .apple-box{
    background-size: 100% 100%;
  } */
  .whiskey-data .blend-box, .whiskey-data .honey-box, .whiskey-data .apple-box{
    height: 365px;
    margin-bottom: 16px;
    width: 100%;
  }
  .whiskey-data h1.lead{
    font-size: 35px;
  }
  .whiskey-data .blend-box img, .whiskey-data .honey-box img, .whiskey-data .apple-box img{
    max-width: 104px;
    padding-top: 15px;
  }
  .whiskey-data .blend-box h2, .whiskey-data .honey-box h2, .whiskey-data .apple-box h2{
    margin: 10px auto auto;
    font-size: 16px;
  }
  .whiskey-data {
    margin-bottom: 10px;
  }
  .whiskeys-page, .whiskey-drinks-page, .where-buy-seagram{
    padding-top: 50px;
  }
  .title h1.lead, .features .card .card-title{
    font-size: 24px;
    line-height: 1;
  }
  .title .description, .features p{
    font-size: 14px;
  }
  .features h1.lead, .features h4{
    font-size: 12px;
  }
  .seagram-drinks .wiskey-desc {
    max-width: 53%;
  }
  br{
    display: none;
  }
  .forMobile{
    display: block !important;
    text-align: start;
  }
  .forDekstop{
    display: none !important;
  }
  /* .whiskey-drinks-page .mobileContent{
    background-image: url(../public/images/our-recipes-detail-hero-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  } */
  .where-buy-seagram .filter h1.lead, .where-buy-seagram .inner h1.lead, .newsLetter-container .stayTouch h1{
    font-size: 35px;
  }
  .mt-120{
    margin-top: 20px !important;
  }
  .where-buy-seagram button.btn-lg, .newsLetter-container .btnDiv .btn-lg{
    padding: 13px;
    font-size: 18px;
    font-weight: 600;
    height: inherit;
  }
  .shop-online-box{
    margin-top: 50px !important;
  }
  .inner .copy{
    display: none;
  }

  .whiskey-drinks-page .text-center .img-fluid{
    max-width: 125px !important;
  }
  .whiskey-drinks-page .title h2{
    margin-bottom: 2px !important;
    font-size: 9px;
  }
  .whiskey-drinks-page .title h1.lead, .whiskeys-page .title h1.lead {
    font-size: 25px;
    line-height: 1;
    margin-bottom: 10px;
  }
  .whiskey-drinks-page .hidden-xs h1.lead{
    font-size: 25px;
    text-align: center;
  }
  /* .whiskeys-page .title h1.lead{
    font-size: 24px;
    margin-bottom: 10px;
  } */
  .whiskeys-page .brandImg{
    max-width: 60px;
  }
  .container-fluid .copy-container{
    margin: 100px auto auto;
  }
  .dive-bar-content.mission p{
    margin-bottom: 15px;
  }
  .container-fluid .copy-container{
    margin: 50px auto auto;
  }
  .dive-bar-content.mission, .give-back-partner {
    padding: 40px 13.5px;
  }
  section.error-page{
    margin: 50px 0 70px;
  }
  section.error-page h1{
   margin-bottom: 15px;
   font-size: 35px;
  }
  section.error-page h1 span{
    font-size: 100px;
  }
  section.error-page h2 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 25px;
  }
  section.error-page p {
    font-size: 15px;
  }
  .prev-btn, .next-btn {
    top: 99px !important;
  }
  p.othertext {
    font-family: CentGothWGL;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.8999999761581421px;
    text-align: left;
    margin-top: 10px;
}
}


@media screen and (max-width: 530px){
  .seagram-drinks .wiskey-desc a{
    bottom: 20px;
  }
  a.btn-lg, button.btn-lg, .seagram-drinks .explore-btn{
    font-size: 9px;
  }
  .btn-lg img[alt= "arrow"], a img[alt= "arrow"] {
    padding-bottom: 3px;
    height: 13px;
  }
  .banner {
    padding: 10px 0;
    height: 350px;
  }
  h1.lead{
    font-size: 30px;
  }
  .find-it a img[alt= "arrow"] {
    height: 23px;
    padding-bottom: 8px;
  }
  .seagram-drinks .lead{
    margin: 20px 0;
  }
  .seagram-drinks{
    padding: 40px 0;
  }
  .seagram-drinks .wiskey-desc p {
    font-size: 10px;
    line-height: 1.3;
  }
  .seagram-drinks .wiskey-desc a img {
    padding: 0 0 3px 2px;
    height: 18px;
    width: auto;
  }
  .seagram-drinks img[alt= "arrow"].p-0 {
    height: 15px;
    margin-top: -5px !important;
    width: auto;
  }
  footer{
    padding: 20px 0;
  }
  .whiskeys-page .title h1.lead{
    font-size: 18px;
  }
  .whiskeys-page .title .description, .features p{
    margin: 0;
  }
  .whiskeys-page .title .description, .features p, .whiskeys-page .description, .whiskeys-page .features h1.lead, .whiskeys-page p, .whiskeys-page .features h4, .whiskeys-page .features .card .card-title{
    font-size: 10px;
  }
  .whiskeys-page a.btn-lg {
    font-size: 10px;
    line-height: 12px;
    margin-top: 10px;
  }
  .features .feature-img{
    width: 150px;
  }
  .container-fluid .copy-container{
    gap: 25px;
  }
  .container-fluid .copy-container h1{
    font-size: 35px;
  }
  .dive-bar-content.mission h2, .give-back-partner h2{
    margin: 10px;
    font-size: 35px;
  }
  .container-fluid .copy-container p{
    font-size: 13px;
  }
  .give-back-partner .main-street-img {
    width: 200px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .dive-bar-content.mission p, .give-back-partner .partner-content{
    font-size: 14px;
    line-height: 30px;
  }
  .social-logo-container img {
    width: 45px;
    margin: 0 30px;
  }
  section.error-page{
    margin: 25px 0 35px;
  }
  section.error-page h1{
   margin-bottom: 7px;
   font-size: 20px;
  }
  section.error-page h1 span{
    font-size: 50px;
  }
  section.error-page h2 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 25px;
  }
  .prev-btn, .next-btn {
    top: 65px !important;
  }
  .seagram-drinks img{
    width: 95px;
  }
}