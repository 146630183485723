@import url(//hello.myfonts.net/count/35287f);
@font-face{
  font-family:'KabelLTStd-Black';
  src:url(../public/fonts/webfonts/35287F_0_0.eot);
  src:url(../public/fonts/webfonts/35287F_0_0.eot?#iefix) format('embedded-opentype'),
  url(../public/fonts/webfonts/35287F_0_0.woff) format('woff'),url(../public/fonts/webfonts/35287F_0_0.ttf) format('truetype')
}

@font-face{
  font-family:'CentGothWGL-Italic';
  src:url(../public/fonts/webfonts/35287F_1_0.eot);
  src:url(../public/fonts/webfonts/35287F_1_0.eot?#iefix) format('embedded-opentype'),
  url(../public/fonts/webfonts/35287F_1_0.woff) format('woff'),
  url(../public/fonts/webfonts/35287F_1_0.ttf) format('truetype')
}
 @font-face{
  font-family:'CentGothWGL';src:url(../public/fonts/webfonts/35287F_2_0.eot);
  src:url(../public/fonts/webfonts/35287F_2_0.eot?#iefix) format('embedded-opentype'),
  url(../public/fonts/webfonts/35287F_2_0.woff) format('woff'),
  url(../public/fonts/webfonts/35287F_2_0.ttf) format('truetype')
}  
@font-face{
  font-family:'CentGothWGL-Bold';
  src:url(../public/fonts/webfonts/35287F_3_0.eot);
  src:url(../public/fonts/webfonts/35287F_3_0.eot?#iefix) format('embedded-opentype'),
  url(../public/fonts/webfonts/35287F_3_0.woff) format('woff'),
  url(../public/fonts/webfonts/35287F_3_0.ttf) format('truetype')
}
@font-face{
  font-family:'CentGothWGL-BdIt';
  src:url(../public/fonts/webfonts/35287F_4_0.eot);
  src:url(../public/fonts/webfonts/35287F_4_0.eot?#iefix) format('embedded-opentype'),
  url(../public/fonts/webfonts/35287F_4_0.woff) format('woff'),
  url(../public/fonts/webfonts/35287F_4_0.ttf) format('truetype')
}
body,
html {
  font-family: "KabelLTStd-Black", sans-serif !important;
  font-size: 18px;
  width: 100%;
  height: 100%;
  padding: 0;
  color: #333333;
  letter-spacing: 0.9px;
  text-rendering: geometricPrecision;
}
 div h1, div h2, div h3, div h4, div h5, div h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #333333;
  letter-spacing: 0.9px;
  text-rendering: geometricPrecision;
  line-height: 1.1;
}
body h1{
  text-transform: uppercase;
}
.main-div {
  position: relative;
  transition: transform 400ms ease-in-out 0s;
}
#main {
  min-height: calc(100vh - 404px);
}
.colorRed{
  color: #c93c2b;
}
.sLwbX {
  padding-top: 60px;
  padding-bottom: 80px;
}
.list-inline{
  margin: 0;
}
.explore-btn {
  margin-top: 60px;
}
.cursor-pointer{
  cursor: pointer;
}

/* Regular rules page */
.sweepstakes {
  text-align: center;
  color: #c93c2b;
  height: 100%;
  padding-bottom: 50px;
}
.container-fluid .closed-hero {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.container-fluid .copy-container {
  display: flex;
  text-align: center;
  color: #fff;
  flex-direction: column;
  align-items: center;
  margin: 150px auto auto;
  gap: 50px;
  width: 80%;
}
.container-fluid .copy-container h1 {
  font-size: 58px;
  color: #f0efd9;
  padding-bottom: 25px;
  border-bottom: 1px solid #f0efd9;
}
.closed-hero-mobile {
  display: none;
}
@media (max-width: 1250px) {
  .closed-hero-mobile {
    display: inline-block;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -9;
    padding-bottom: 100px;
    background-color: #000;
  }
  .closed-hero {
    display: none;
  }
}

.container-fluid .copy-container p {
  font-size: 28px;
  color: #f0efd9;
  text-transform: uppercase;
  max-width: 760px;
}
.container-fluid .social-logo-container {
  display: flex;
  text-align: center;
}
.social-logo-container img {
  width: 50%;
}
.dive-bar-content.mission {
  background: #f1f0dd;
  text-align: center;
  width: auto;
  padding: 150px 0;
}
.dive-bar-content.mission h2 {
  text-transform: uppercase;
  color: #c93c2b;
  margin: 20px 0 50px;
  font-size: 70px;
}
.dive-bar-content.mission p {
  color: #000000;
  font-size: 18px;
  line-height: 40px;
  font-family: "CentGothWGL";
  margin-bottom: 30px;
}
.dive-bar-content.mission .mission-content a{
  color: #c93c2b;
  text-decoration: none;
}
.dive-bar-content.mission .mission-logo {
  width: 66px;
}
.give-back-partner {
  padding-top: 0;
  background-image: url(/public/images/simon-stankowski-253345.png);
  background-size: cover;
  background-position: center center;
  padding: 150px 0;
  background-color: #262625;
  display: flex;
  align-items: center;
  text-align: center;
}
.give-back-partner h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 50px;
  letter-spacing: 0;
}
.give-back-partner .main-street-img {
  width: 300px;
  margin-bottom: 40px;
  margin-top: 20px;
}
.give-back-partner .partner-content {
  color: #fff;
  font-family: "CentGothWGL";
  line-height: 40px;
}
/* /// End css Regular rules page */

/* Start Mohit */
/* for header */
nav.navbar{
  height: 90px;
}
#basic-navbar-nav .nav-link{
  color: #c93c2b;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  margin: 10px;
  /* padding: 15px 15px 13px; */
  cursor: pointer;
  border-bottom: 2px solid transparent;
  /* letter-spacing: 0.5px; */
}
#basic-navbar-nav .nav-link.active{
  border-bottom: 2px solid #c93c2b;
}
.header-white #basic-navbar-nav .nav-link, .header-white #basic-navbar-nav span{
  color: #ffffff;
}
#basic-navbar-nav .btn-dive-bar-header {
  background: #c93c2b;
  transition: all .2s;
  color: #fff;
  border: 0;
  padding: 5px 10px;
  font-size: 18px;
  position: absolute;
  right: 0px;
  top: 15px;
  font-weight: normal;
  line-height: 25.7px;
}
.navbar-toggler {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.navbar-toggler .icon-bar{
  width: 40px;
  height: 2px;
  background-color: #c93c2b;
  display: block;
  margin-top: 8px;
}
/* body>header .navbar .navbar-toggle .icon-bar {
  width: 40px;
  height: 2px;
  background-color: #c93c2b;
} */
.mobileLogo{
  display: none;
}
/* for home page  */  
.banner {
    background-image: url(/public/images/HeroImageDesktop-new.png);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    text-align: left;
    color: #696969;
    padding: 40px 0 ;
    max-height: 600px;
    height: 600px;
}
h1.lead {
  text-align: left;
  margin-bottom: 10px;
  color: #c93c2b!important;
  font-size: 65px;
  font-weight: 700;
  line-height: 1;
}
.banner p{
  font-size: 17px;
  line-height: 2;
  margin-bottom: 20px;
  font-family: 'CentGothWGL';
  color: #696969;
}
#age_logo_img{
  width: 40.5px !important;
  height: 65px !important;
}
a.btn-lg, button.btn-lg {
  background-color: #c93c2b;
  padding: 0 16px;
  text-transform: uppercase;
  font-family: "KabelLTStd-Black";
  font-size: 22px;
  height: 50px;
  line-height: 51px; 
 letter-spacing: 0.3em;
  border-radius: 0;
  color: #ffffff;
  border-color: #c93c2b !important;
}
a.btn-lg{
  background-color: #c93c2b !important;
  color: #ffffff !important;
}
button.btn-lg{
  width: 100%;
  max-width: 100%;
  display: block;
  padding: 13px;
  font-size: 18px;
  line-height: unset;
  letter-spacing: unset;
}
a.btn-lg:hover {
  background-color: #c93c2b !important;
  color: #fff;
}

.seagram-drinks{
  padding: 60px 100px;
  background-color: #FBF7F3;
}
.seagram-drinks .lead{
  font-size: 70px;
  margin: 20px 0 50px;
}

.seagram-drinks img.img-fluid{
  margin-left: 20px;
}
.blend-box, .honey-box, .apple-box{
  height: 320px;
  max-height: 100%;
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: bottom;
}
.blend-box{
  background-image: url(../public/images/bigger_red.png);
}
.honey-box{
  background-image: url(../public/images/bigger_yellow.png);
}
.apple-box{
  background-image: url(../public/images/bigger_green.png);
}
.seagram-drinks .wiskey-desc{
  max-width: 200px;
  margin-left: auto;
}
.seagram-drinks .wiskey-desc{
  padding: 48px 20px 10px 10px;
  position: relative;
}
.seagram-drinks .wiskey-desc h3{
  color: #ffffff;
  font-size: 21px;
  text-transform: uppercase;
  line-height: 1.1;
}
.seagram-drinks .wiskey-desc p{
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'CentGothWGL';
  color: #ffffff;
  margin: 0;
  line-height: 18.5px;
}
.seagram-drinks .wiskey-desc a{
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  position: absolute;
  bottom: 10px;
}
.seagram-drinks .wiskey-desc a img{
  padding: 0 0 5px 10px;
}
.seagram-drinks .explore-btn{
  padding: 0 16px;
  text-transform: uppercase;
  font-family: "KabelLTStd-Black";
  font-size: 22px;
  height: 50px;
  line-height: 51px;
  letter-spacing: .3em;
  background-color: #c93c2b !important;
  border-color: transparent;
  border-radius: 0;
  border: 0;
}
.find-it {
  background-image: url(../public/images/simon-stankowski-253345.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 60px 0 40px;
  max-height: 100%;
  height: 315px;
}
 .find-it h2 {
  text-transform: uppercase;
  font-family: "KabelLTStd-Black";
  font-size: 70px;
}
.find-it p {
  line-height: 2;
  margin-bottom: 20px;
  font-family: "CentGothWGL";
}

.find-it a {
  background-color: #fbf7f3 !important;
  color: #000000 !important;
  padding: 0 16px;
  text-transform: uppercase;
  font-size: 22px;
  height: 50px;
  line-height: 51px;
  border-color: transparent;
  letter-spacing: 0.3em;
  border-radius: 0;
}
.find-it a:hover {
  background-color: #fbf7f3;
  color: #000;
}
/* /// Home page css end */

/* whiskey page css */
.whiskey-data{
  padding: 60px 0 170px;
}
.whiskey-data h1.lead{
  font-size: 70px;
  font-weight: 500;
  line-height: 1.1;
}
.whiskey-data a{
  text-decoration: none;
}

.whiskey-data .blend-box, .whiskey-data .honey-box, .whiskey-data .apple-box{
  text-align: center;
  height: 408px;
  width: 100%;
  max-width: 100%;
}
.whiskey-data .blend-box img, .whiskey-data .honey-box img, .whiskey-data .apple-box img{
  max-width: 121px;
  width: 100%;
}
.whiskey-data .blend-box h2, .whiskey-data .honey-box h2, .whiskey-data .apple-box h2{
  font-size: 30px;
  text-decoration: none;
  max-width: 300px;
  margin: 45px auto 30px;
}
.whiskey-data .blend-box h2{
  color: #c93c2b;
}
.whiskey-data .honey-box h2{
  color: #cfbc32;
}
.whiskey-data .apple-box h2{
  color: #636836;
}
/* /// whiskey page css end */

/* whiskeys-page css */
.prev-btn, .next-btn{
  background-color: transparent !important;
  border: 0 !important;
  opacity: 0.60;
  position: relative;
  top: 150px !important;
}
.next-btn{
  transform: rotate(180deg);
}
.prev-btn:hover, .next-btn:hover{
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}
.whiskeys-page, .whiskey-drinks-page, .where-buy-seagram{
  background-color: #FBF7F3;
  padding: 75px 0 20px;
}
.title h1.lead{
  font-size: 70px;
  font-weight: 500;
  line-height: 1.1;
  margin: 0;
}
.title .description{
  font-family: 'CentGothWGL',sans-serif;
  line-height: 1.4;
}
.features h1.lead, .features h4{
  font-family: 'KabelLTStd-Black',sans-serif;
  font-size: 18px;
  text-transform: uppercase;
}
.features h4{
  margin-top: 10px;
}
.features h1.lead{
  font-family: 'KabelLTStd-BlackL',sans-serif;
  border-bottom: 1px solid #cccccc;
  margin: 12px 0 16px;
  letter-spacing: 0;
}
.features p{
  font-family: 'CentGothWGL',sans-serif;
  margin-bottom: 10px;
}
.features .card{
  width: fit-content;
  border-radius: 0;
  background: transparent;
  border: 0;
}
.features .card .card-title{
 font-size: 30px;
}
.features .feature-img{
  width: 250px;
  box-shadow: 0 0 5px #bbb;
  border: 10px solid #ffffff;
}
.seagrams-blended-whiskey .changeColor{
  color: #c93c2b!important;
}
.seagrams-apple-whiskey .changeColor, .apple_cran .changeColor, .apple_cran .changeColorA a{
  color: #636836 !important;
}
.seagrams-honey-whiskey .changeColor, .honey_lemonade .changeColor, .honey_lemonade .changeColorA a{
  color: #cfbc32 !important;
}
.additionalparagraph{
  color: #333;
font-family: CentGothWGL;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
letter-spacing: 0.9px;
}
/* /// whiskey-pages css end */


/* whiskey-drinks-page  */
.forMobile, .forMobileMenu{
  display: none !important;
}
.whiskey-drinks-page .title h2{
  font-family: "KabelLTStd-Black";
  color: #999;
  text-transform: uppercase;
  margin-bottom: 20px!important;
  font-size: 21px;
  font-weight: 300;
}
.whiskey-drinks-page .title h1.lead{
  font-size: 65px;
  line-height: 70px;
}
.whiskey-drinks-page .hidden-xs ul{
    font-family: 'CentGothWGL';
    padding-left: 20px;
}
.whiskey-drinks-page .hidden-xs ul li{
  line-height: 1.4;
}
.whiskey-drinks-page .hidden-xs ul li a{
  color: #c93c2b;
  text-decoration: none;
}
.whiskey-drinks-page .hidden-xs ul li a:hover{
  text-decoration: underline;
}
.whiskey-drinks-page .hidden-xs h1.lead{
  text-transform: uppercase;
  border-bottom: 1px solid #999999;
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 0;
}
ul.social{
  padding: 0;
  list-style-type: none;
}
ul.social li{
  margin-right: 10px;
}
/* /// whiskey-drinks-page end  */

/* where-buy-seagram css */
.where-buy-seagram{
  padding-bottom: 135px;
}
.where-buy-seagram .filter h1.lead{
  font-size: 70px;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 1.1;
}
.where-buy-seagram .inner{
  text-align: center;
  max-width: 330px;
  width: 100%;
  margin: auto;
}
.where-buy-seagram .inner h1.lead {
  font-size: 45px;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: 0;
}
.inner .copy{
  min-height: 65px;
  height: 100%;
  margin-bottom: 15px;
}
.inner .icon{
  font-size: 65px;
  color: #c93c2b;
  margin: 20px 0 30px;
}
.inner .icon .svg-inline--fa path{
  fill: #c93c2b;
}
.mt-120{
  margin-top: 120px !important;
}
.inner .copy p {
  color: #c93c2b;
  letter-spacing: .1rem;
  line-height: 1.2;
}
.mapboxgl-ctrl-geocoder{
  width: 100% !important;
  max-width: 100% !important;
  border: solid 2px #c93c2b;
  background-color: transparent !important;
  border-radius: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  height: 50px;
}
.mapboxgl-ctrl-geocoder--icon{
  top: 13px !important;
  fill: #c93c2b !important;
  width: 20px !important;
  height: 20px !important;
}
.mapboxgl-ctrl-geocoder input{
  color: #c93c2b !important;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 18px !important;
}
.mapboxgl-ctrl-geocoder--button, .mapboxgl-ctrl-geocoder--button{
  background-color: transparent;
  top: 11px;
  width: 20px;height: 20px;
  height: 20px;
}
.form-check label a{
  color: #c93c2b !important;
}
ul.suggestions{
  text-align: start;
}
ul.suggestions li {
  text-transform: uppercase;
  font-weight: bold;
}
.mapboxgl-ctrl-geocoder--powered-by{
  display: none !important;
}
/* /// where-buy-seagram css end */

/* newsLetter-container css start */
.newsLetter{
  padding: 10px 0;
}
.newsLetter .stayTouch h1{
  font-size: 70px;
}
.newsLetter .stayTouch p{
  color: #c93c2b; 
}
.newsLetter input.form-control, .newsLetter select.form-select, .newsLetter textarea.form-select{
  border-radius: 0;
  border: 1px solid #000000;
  padding: 10px;
  box-shadow: none;
  font-family: 'CentGothWGL';
}
.newsLetter .touchForm span{
  color: #c93c2b;
  font-family: 'CentGothWGL';
  font-size: 13px;
  font-weight: bold;
}
.newsLetter .form-check input{
  box-shadow: none;
  width: 13px;
  height: 13px;
  border-color: rgb(51, 51, 51);
  margin-top: 7px;
  cursor: pointer;
}
.newsLetter .form-check label, .newsLetter .form-check-label{
  font-family: 'CentGothWGL';
  font-size: 16px;
  text-align: left;
  font-weight: 700;
  width: fit-content;
  cursor: pointer;
}
.newsLetter .btnDiv .btn-lg{
  max-width: 200px;
  font-weight: 600;
  width: 100%;
  height: 44px;
  line-height: normal;
  padding: 8px;
  font-size: 16px;
}
/* /// newsLetter-container css start end */

/* Footer start */
footer {
  font-family: "CentGothWGk";
  font-size: 12px;
  background: #c93c2b;
  color: #ffffff;
  padding: 40px 0;
  text-align: center;
  z-index: 8;
  position: absolute;
  width: 100%;
}

#dg_footer_wrapper_inner ul li:after{
  display: none !important;
}

.dg_footer_item_last{
  float: none !important;
  width: 100%;
  padding: 10px 0;
}

#dg_footer_wrapper_inner .dg_footer_row_0 a, #dg_footer_wrapper_inner .dg_footer_row_0 a:hover {
  color: #fff!important;
}

#dg_footer_wrapper_inner ul li span {
  color: #fff!important;
}

#dg_footer_wrapper{
  background-color: transparent !important;
}

.list-inline a {
  text-decoration: none;
  color: #ffffff !important;
  padding: 10px 0;
  text-transform: uppercase;
}

footer p>strong{
  font-size: 12px;
  color: #ffffff;
  font-family: "CentGothWGL";
}

footer ul li.list-inline-item, footer ul span, footer ul a{
  padding: 10px 0;
  color: #fff;
  font-family: Arial,sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 20px;
}
footer ul li{
  padding-top: 10px;
}
/* /// Footer css enc */

/* shop online css strat */
.shop-online h1.lead {
  font-size: 70px;
  margin-bottom: 30px;
  font-weight: 500;
}
.shop-online{
  margin-top: 15px;
  background-color: #FBF7F3;
  padding: 50px 0 0;
}
/* /// shop online css end */
.navbar .social-icon span{
  color: #c93c2b;
}
.navbar ul.social {
  width: 98px;
  justify-content: space-around;
  margin: 0;
  display: none !important;
}
.navbar ul.social li{
  margin: 0 !important;
  opacity: 0.3;
}
.navbar ul.social li:hover{
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.btnDanger{
  background-color: #c93c2b !important;
}
.btnDanger:hover{
  background-color: #e94634 !important;
}
.navbar ul.social img {
  width: 24px;
  height: 24px;
}
.navbar .iconHide ul.social{
  display: flex !important;
}
.navbar .social-icon .iconHide span{
  display: none;
  transition: display 500ms ease-in-out;
}

.navbar .changeImg{
  display: none;
}

.header-white .navbar .defaultImg{
  display: none;
}
.header-white nav.navbar{
  z-index: 99;
}
.header-white .navbar .changeImg{
  display: block;
}
a.btn-lg:hover, button.btn-lg:hover, #basic-navbar-nav .btn-dive-bar-header:hover {
  background-color: #e94634;
  color: #f3f3f3;
}

.btn-lg img[alt= "arrow"], a img[alt= "arrow"]{
  padding-bottom: 10px;
}

/* error page css */
section.error-page{
  margin: 90px 0 140px;
}
section.error-page h1{
 margin-bottom: 30px;
 font-size: 70px;
}
section.error-page h1 span{
  font-size: 200px;
}
section.error-page h2 {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 50px;
  letter-spacing: 0;
  text-align: center;
}
section.error-page p {
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: .04em;
  text-align: center;
}
section.error-page p a{
  color: #e94634;
  text-decoration: none;
}

/* newsletter modal */
.modal-header .closeBtn {
  padding: 0 !important;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #c93c2b !important;
  font-family: sans-serif;
  font-weight: normal;
}
.modal-header .stayTouch h2, .successModal .stayTouch h2 {
  font-size: 30px;
}
.successModal .modal-dialog{
  max-width: 560px;
}
.successModal a{
  color: #c93c2b !important;
  text-decoration: none;
}
.modal-body a{
  text-decoration: none;
}
.modal-body a:hover{
  text-decoration: underline;
  color: #8a291e !important;
}
.modal-content{
  border-radius: 0 !important;
}
.newsLetter .modal-body .form-check label {
  font-size: 16px;
  width: 80%;
  margin-bottom: 5px;
}
.modal-body button{
  font-weight: 600;
  letter-spacing: normal;
}


.modal__backdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}
.modal__container {
	background: #fff;
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 560px;
}
.modal__title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

.newsLetter input[type=date]::before {
  content: attr(placeholder);
  position: absolute;
  color:  var(--boxAfterBackColor, #5c6268);
}
.newsLetter input[type=date]:focus {
  color: #212529 !important;
}

.newsLetter .input, .newsLetter input[type=date]{
  position: relative;
}
.newsLetter input[type=date]:before{
  content: attr(placeholder);
 position: absolute;
 left: 1px;
 right: 33px;
 top: 1px;
 bottom: 1px;
 padding: 10px;
 background-color: #ffffff;
}
.newsLetter input[type=date]:focus::before{
  content: none;
}
.newsLetter .rem-placeholder::before{
  content: none !important;
  display: none !important;
}


.backBtn {
  background: transparent;
  border: 0;
  position: relative;
  left: 0;
  text-align: start;
  padding-bottom: 10px;
}

.error input[type=checkbox] {
  outline: 1px solid #c93c2b;
  border-color: #c93c2b;  
}
/* neww css for multiselect dropdown */
.multselect-dropdown .css-13cymwt-control, .multselect-dropdown .css-t3ipsp-control {
  border-radius: 0 !important;
  border: 1px solid #000000 !important;
  padding: 1.5px !important;
  box-shadow: none;
  font-family: 'CentGothWGL';
}
/* new css on 30/01/2024 */
#salesforceForm .form-control, #salesforceForm .form-select {
  border-radius: 0;
  border: 1px solid #000;
  box-shadow: none;
  padding: 10px;
  font-family: CentGothWGL;
  color: #6c757d;
}
.stayTouch h1 {
  padding-bottom: 50px;
  font-size: 70px;
}
#salesforceForm .form-check {
  width: fit-content;
  cursor: pointer;
  font-family: CentGothWGL;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  display: block;
  padding-left: 1.5em;
  min-height: auto;
  padding-bottom: 5px;
}
#salesforceForm .form-check-input{
  border-color: #333;
  box-shadow: none;
  cursor: pointer;
  height: 13px;
  width: 13px;
}
#salesforceForm .btnDiv {
  text-align: center;
  padding: 8px;
  /* cursor: pointer; */
}
#salesforceForm button.btn.btn.btn-primary {
  background-color: #c93c2b;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  height: 60px;
  line-height: normal;
  padding: 10px 40px;
  border-color: #c93c2b;
  border-radius: 0;
  color: #fff;
  font-family: KabelLTStd-Black;
  cursor: pointer;
  text-transform: uppercase;
}
form#salesforceForm {
  margin-bottom: 50px;
}

#salesforceForm .input span {
    color: #c93c2b;
    font-family: CentGothWGL;
    font-size: 13px;
    font-weight: 700;
}
#salesforceForm select{
  color:#212529;
}
.multioptions{
  color:#fff!important;
}
#dg_footer_wrapper_inner ul li a {
  padding: 0 10px !important;
}
.recapta-group div div div {
  width: 100% !important;
  text-align: center;
  height: 80px !important;
}
#salesforceForm .form-select {
  margin-bottom: 0 !important;
}
.recapta-group {
  padding-top: 30px;
}

.othertext {
  font-family: CentGothWGL;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.8999999761581421px;
  text-align: left;
  color: #333;
}

/* navigation issue fixed by shrikant */
@media (min-width: 992px){
  #basic-navbar-nav {
    margin-right: 13%;
    justify-content: center;
}
#basic-navbar-nav .btn-dive-bar-header {
  right: 15px;
}
}

@media only screen and (max-width: 1072px) and (min-width: 990px)  {
  #basic-navbar-nav .btn-dive-bar-header {
    right: 1%;
  } 
  .nav-bar .nav-link {
    font-size: 13px !important;
    margin: 3px !important;
    padding: 10px 5px !important;
}
.btn-header-regularsrules{
  font-size: 13px !important;
    margin: 3px !important;
    padding: 10px 5px !important;

}
}


@media screen and (max-width: 529px){
.banner {
    height: 290px!important;
    padding: 10px 0;
}
.banner.row .col-lg-4.col-sm-5.col-6.offset-md-1 {
  padding-left: 8px;
}
.banner.row .col-lg-4.col-sm-5.col-6.offset-md-1 {
  width: 44%;
}



}
@media screen and (max-width: 529px){
  .banner {
      height: 330px!important;
      padding: 10px 0;
  }
}


@media (max-width: 1024px){
.banner {
  background-image: url(/public/images/tab-img-banner.jpg);
}
h1.lead {
  font-size: 50px;
}
.banner {
  padding: 20px 0;
  max-height: 500px;
}
}

@media (max-width: 768px){
  .banner.row .col-lg-4.col-sm-5.col-6.offset-md-1 {
    margin-left: 0.333333%;
}
.banner {
  background-image: url(/public/images/tab-img-banner.jpg);

}
h1.lead {
  font-size: 50px;
}
.banner {
  padding: 20px 0;
  max-height: 500px;
}
}
@media (max-width: 450px){
.banner {
  background-image: url(/public/images/mobile-banner.png);

}
}
@media (max-width: 374px){
h1.lead {
  font-size: 19px!important;
}
.banner.row .col-lg-4.col-sm-5.col-6.offset-md-1 {
  padding-left: 5px!important;
}
}
@media only screen and (min-width: 1072px) and (max-width: 1300px)  {
  #basic-navbar-nav .btn-dive-bar-header {
    right: 15px!important;
  }
}
/* End navigation issue fixed by shrikant */

/* Whisky Page background color*/
.whiskey-page {
  background: #fbf7f3;
}
/* End Whisky Page background color */

/*Shirikant*/
@media (min-width: 768px){
 /* For recipe banner image first slider image  */
.img-fluid.d-inline-block.img-0, .img-fluid.d-inline-block.img--1 {
  max-width: 75%!important;
}
}



/* whisky page slider button hide */
.seagrams-blended-whiskey button.prev-btn.p-0.btn.btn-primary,
.seagrams-blended-whiskey button.next-btn.p-0.btn.btn-primary{
  display: none;
}
.ingredients ul {
  list-style-type: none;
}
.method b {
  font-weight: 400 !important;
}
@media only screen and (min-width: 767px) and (max-width: 769px)  {
 .changeColorA{
  letter-spacing: .5px;
 }
 .whiskey-drinks-page .hidden-xs ul {
  padding-left: 5px;
}
} 
/* End whisky page slider button hide */